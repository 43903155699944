<template>
  <v-card class="rd elevation-0" :class="{ smallsmallCard:smallsmallCard ,bigCard:bigCard ,filterBigCard:filterBigCard}" >
    <Work :hash="hash" :attribute="attribute" :fileUrl="fileUrl"  :value="value" @imgClick="workClick" :cardType="type" />
    <AuctionCardContent v-if="type === 'auction'" :value="value" />
    <SellCardContent v-else-if="type === 'sell'" :value="value" :isMinPrice="isMinPrice" />
    <MyCardContent v-else-if="type === 'my'" :value="value" />
    <CollectCardContent v-else-if="type === 'collect'" :value="value" />
    <PageCardContent v-else-if="type === 'page'" :value="value"  />
    <OtherWorkCard v-else-if="type === 'other'" :value="value" :owerfrom="owerfrom" :selectType='selectType' />
    <MycollectCard v-else-if="type === 'mycollect'" :value="value" />
     <ClearModeCard v-else-if="type === 'clearmode'" :value="value" />
    <slot></slot>
  </v-card>
</template>

<script>
import Work from "@/components/work/index.vue";
import AuctionCardContent from "./AuctionCardContent.vue";
import SellCardContent from "./SellCardContent.vue";
import MyCardContent from "./MyCardContent.vue";
import CollectCardContent from "./CollectCardContent.vue";
import PageCardContent from "./PageCardContent.vue";
import OtherWorkCard from "./OtherWorkCard.vue"
import MycollectCard from "./MycollectCard.vue"
import ClearModeCard from "./ClearModeCard.vue";

export default {
  name: "Card",
  components: { OtherWorkCard, Work, AuctionCardContent, SellCardContent, MyCardContent, CollectCardContent, PageCardContent, MycollectCard,ClearModeCard },
  props: {
    value: {
      type: Object,
    },
    type: {
      type: String, // auction / sell / my / collect
    },
    owerfrom: {
      type: String,
    },
    favorite: {
      type: String
    },
    isMinPrice: {
      type: String
    },
    pagetype: {
      type: String
    },
    smallCard:{
      type:Boolean
    },
    smallsmallCard:{
       type:Boolean
    },
    bigCard:{
       type:Boolean
    },
    filterBigCard:{
       type:Boolean
    },
    selectType:{
      type:Number
    }
  },
  data: () => ({
    loading: false,
    selection: 1,
  }),
  computed: {
    hash: function () {
      return this.value && this.value.imgUrl;
    },
    attribute: function () {
      return this.value && this.value.attribute;
    },
    fileUrl: function () {
      return this.value && this.value.fileUrl;
    },
  },
  methods: {
    reserve() {
      this.loading = true;
      setTimeout(() => (this.loading = false), 2000);
    },
    workClick() {
      if (this.owerfrom == "personalPage") {
        this.$router.push({ name: "Carddetail", query: { from: this.owerfrom, tokenAddress: this.value.contractAddress, nftId: this.value.nftId, assetId: this.value.id ,owner:this.value.nftId} });
      } else if (this.owerfrom == "auction") {
        if (this.value.auctionNo == null) {
          this.$router.push({ name: "Saledetail", query: { id: this.value.id } });
        } else {
          this.$router.push({ name: "Detail", query: { auctionNo: this.value.auctionNo } });
        }

      } else if (this.owerfrom == "my") {
    this.$router.push({ name: "Carddetail", query: { from: this.owerfrom, tokenAddress: this.value.contractAddress, nftId: this.value.nftId, assetId: this.value.id ,owner:this.value.nftId } });
    
      }else if(this.owerfrom == "create"){
        console.log("create")
      }
      else if(this.owerfrom=="resale"){
         let query = { };
         query.tokenAddress = this.value.contractAddress;
          query.nftId = this.value.nftId;
          query.owner = this.value.owner;
          query.from = "resale";
        if(this.value.makerType== '2'){
         
      this.$router.push({ name: "Detail", query });
        }else{
           this.$router.push({ name: "Saledetail", query });
        }
      }
      else {
        let query = { };
         query.tokenAddress = this.value.contractAddress;
          query.nftId = this.value.nftId;
          query.owner = this.value.owner
        if(this.value.makerType== '2'){
         
      this.$router.push({ name: "Detail", query });
        }else{
           this.$router.push({ name: "Saledetail", query });
        }
        
         

       
      }
    },
    forceUpdate() {
      this.$forceUpdate();
    }
  },
};
</script>


<style lang="scss" scoped>
.v-card.rd {
  // overflow: hidden;
  width: 100%;
  min-height: unset;
  // justify-content: center;
  background-image: linear-gradient(#ffffff, #ffffff),
    linear-gradient(#6f58d9, #6f58d9);
  background-blend-mode: normal, normal;
  // box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  border: 1px solid #cccccc;


  &:hover {
    border-radius: 5px;
    border: solid 2px #1d42ff;
  }

  //  &::v-deep .v-sheet--outlined {
  //    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.09);
  // }

  @media screen and (max-width: 500px ) {

  }

  @media screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
    width: 220px;
    background-image: linear-gradient(#ffffff, #ffffff),
      linear-gradient(#ffffff, #ffffff);
    background-blend-mode: normal, normal;
    // box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
  }
}

.v-card.smallCard {

  @media screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
    width: 212px;
    background-image: linear-gradient(#ffffff, #ffffff),
      linear-gradient(#ffffff, #ffffff);
    background-blend-mode: normal, normal;
    // box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
  }
}
.v-card.smallsmallCard {

  @media screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
    width: 220px;
    background-image: linear-gradient(#ffffff, #ffffff),
      linear-gradient(#ffffff, #ffffff);
    background-blend-mode: normal, normal;
    // box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
  }
}
.v-card.bigCard {

  @media screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
    width: 280px;
    background-image: linear-gradient(#ffffff, #ffffff),
      linear-gradient(#ffffff, #ffffff);
    background-blend-mode: normal, normal;
    // box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
  }
}
.v-card.filterBigCard {

  @media screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
    width: 298px;
    background-image: linear-gradient(#ffffff, #ffffff),
      linear-gradient(#ffffff, #ffffff);
    background-blend-mode: normal, normal;
    // box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
  }
}



</style>

