<template>
  <div>
    <v-card-title class="d-block text--title pt-4 px-5 pb-2 text-truncate"
      >{{value.name}}</v-card-title
    >
    <v-card-text class="d-flex flex-row align-center pb-0 px-5" style="margin-bottom: 18px;">
      <Avatar  size="40" :did="value.owner" :hash="value.ownerPhoto" showAvatarInfo/>
      <div class="ml-4 text-truncate" style="max-width: 100%;">{{value.ownerName}}</div>
    </v-card-text>
  </div>
</template>

<script lang='js'>
import Avatar from '@/components/avatar/index.vue';
import api from "@/api";

export default {
  name: 'SellCardContent',
  components: { Avatar },
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
    async mounted() {
		
		if(!this.value.ownerName){
			this.value.ownerName=this.value.owner.substr(0,6)+"..."+this.value.owner.substr(-6)
		}
		
    },
  data: function() {
    return {
		profilePhoto: ""
	};
  }
};

</script>

<style lang='scss' scoped>
.text--primary-rd {
  color: #270645;
}
.text--secondary-rd {
  color: #766983;
}
.text--title {
  font-size: 15px;
  line-height: 24px;
}
.text--sub-title {
  font-size: 12px;
  line-height: 1;
}
.text--number {
  font-size: 15px;
  line-height: 1;
}
.v-divider {
  border-color: #e3e3e3;
}
</style>
