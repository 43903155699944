<template>
  <div>
    <!-- <v-card-title class="d-block text--title pt-4 px-5 pb-2 text-truncate"
      >{{value.name}}</v-card-title
    > -->
    <!-- <v-card-text class="d-flex flex-row align-center pb-0 px-5 text-avatar"> -->
      <!-- <Avatar  size="40" :did="value.owner" :hash="value.profilePhoto || value.issuerImg" showAvatarInfo/> -->
      <!-- <div class="ml-4 text-truncate" style="max-width: 100%;">{{value.issuerName}}</div> -->
    <!-- </v-card-text> -->

    <v-card-text class="d-flex flex-column px-4 pt-4 pb-4">
      <!-- <v-icon size="15">mdi-facebook</v-icon> -->
      <div class="text-number mb-3">{{value.name}}</div>
      <div class="d-flex flex-row">
          <div class="d-flex item-center">
          <v-img v-if="value.marketPrice" :src="getChainByName(value.marketTokenType).img" alt="logo" width="23px" height="23px"/>
        </div>
        <div class="ml-2 text--number text--primary-rd font-weight-medium" style="line-height:22px;">
          {{value.marketPrice}}
        </div>
      </div>
    </v-card-text>

    <!-- xxl 474 -->
    <div style="float:right;display:flex;z-index:100;margin-top:-56px;margin-right:10px" v-if="value.saleStatus == 1">
      <v-img src="@/assets/icons/icon_onsale.png" alt="logo" width="71" height="56px"/>
    </div>

  </div>
</template>

<script lang='js'>
// import Avatar from '@/components/avatar/index.vue';
// import countLogo from "@/assets/icons/chain_iris_w.png";

export default {
  name: 'SellCardContent',
  // components: { Avatar },
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: function() {
    return {
      // countLogo
    };
  },

};

</script>

<style lang='scss' scoped>
.text--primary-rd {
  color: #270645;
}
.text--secondary-rd {
  color: #766983;
}
.text--title {
  font-size: 20px;
  line-height: 24px;
}
.text--sub-title {
  font-size: 12px;
  line-height: 1;
}
.text-number{
  width: 190px;
  font-family: Helvetica;
	font-size: 13px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 13px;
	letter-spacing: 0px;
	color: #270645;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.text--number {
  font-size: 15px;
  line-height: 1;
  font-weight: bold !important;
}
.v-divider {
  border-color: #e3e3e3;
}
</style>
