<template>
	<div style="overflow: hidden;height:125px;" class="pb-3">
		<v-card-title class="text--title px-5 py-4">{{
      value.name ? value.name : "No Name"
    }}</v-card-title>
		<v-card-text class="d-flex flex-row align-center justify-space-between px-5 pb-6" style="height: 49px">
			<div class="d-flex flex-row mt-2" v-if="value.makerType != '2' && value.sellout != true">
				<div>
					<v-img :src="getChainByName(value.marketTokenType).img" alt="logo" width="22px" height="22px" />
				</div>
				<div class="ml-2 text--number text--primary-rd " style="line-height: 22px;font-weight:bold;!important">
					{{ value.marketPrice }}
				</div>
			</div>
			<div class="d-flex flex-row mt-2" v-if="value.makerType != '2' && value.sellout == true">
				<div>
					<v-img :src="getChainByName(value.issuerTokenType).img" alt="logo" width="22px" height="22px" />
				</div>
				<div class="ml-2 text--number text--primary-rd " style="line-height: 22px;font-weight:bold;!important">
					{{ value.issuerPrice }}
				</div>
			</div>
			<v-spacer></v-spacer>

			<div class="d-flex flex-row align-center">
				<!-- 销毁弹层按钮 -->
				<img class="ml-5" src="@/assets/icons/icon_burn.png" width="25px" height="25px" @click="burnBtn"
					v-if="value.isBurn == true" alt="" />

				<!-- 销售统计弹层按钮 -->
				<img v-if="value.creator == this.$store.state.did" class="ml-5" src="@/assets/icons/icon_data.png"
					width="25px" height="25px" style="z-index:1; " @click="saleTotalBtn" alt="" />
			</div>
		</v-card-text>
		<v-card-text class="py-0 px-5" style="height: 44px">
			<template v-if="value.makerType == '1' || value.makerType == null">

				<!-- 被禁止/被投诉 -->
				
				<template v-if="value.assetStatus>1||value.contractStatus > 1">
					<div v-if="value.assetStatus==4" class="d-flex justify-space-between">
						<div v-if="value.creator != this.$store.state.did" class="d-flex flex-row">
								<img class="mt-n2 ban" src="@/assets/icons/ban.png" width="20px" height="20px" alt=""
							@mouseenter="moused()" @mouseleave="leave()" />
							<div class="explain d-flex flex-row align-center" v-if="show">
							<span>Suspected of violations and has been restricted.</span>
						</div>
						</div>
						<div v-else class="d-flex flex-row">
							<div class="mt-n2 text-wrap text--info text--secondary-rd">
							{{ $t("workCardSuspected") }}
						</div>
						<v-btn class="haveAppeal mt-n2 mr-n3" dark rounded width="100" height="28">
							{{ $t("headerUserCenterNavAppealed") }}
						</v-btn>
						</div>
						
					</div>
					<div v-else class="d-flex justify-space-between">
						<!-- <div class="mt-1 text-wrap text--info text--secondary-rd">
                  {{ $t("workCardSuspected") }}
               </div> -->
						<img class="mt-n2 ban" src="@/assets/icons/ban.png" width="20px" height="20px" alt=""
							@mouseenter="moused()" @mouseleave="leave()" />
						<v-btn v-if="value.assetStatus==2&&value.creator == this.$store.state.did" class="sale ml-4" dark rounded width="100"
							height="28" @click="AppealBtn">{{ $t("workCardAppeal") }}
						</v-btn>
						<div class="explain d-flex flex-row align-center" v-if="show">
							<span>Suspected of violations and has been restricted.</span>
						</div>
					</div>
				</template>
				<!-- 已上架，显示下架按钮 -->
				<template v-else-if="value.listAmount > 0">
					<v-btn class="sale" dark rounded depressed width="178" height="28" @click="EndSaleBtn">
						{{ $t("popupSoldoutEndsale") }}
					</v-btn>
				</template>
				<!-- 已下架，显示上架按钮 -->
				<template v-else-if="value.listAmount == 0 && value.amount > 0 &&value.sellout==false">
					<div class="d-flex justify-space-between" style="margin-top: -8px">
						<div class="btnbox">
							<v-btn class="rd" rounded @click="TransferBtn">{{ $t("popupNewTransferTransfer") }}
							</v-btn>
						</div>
						<!-- 收藏部分的上架按钮  替换为 上架按钮 -->
						<template>
							<v-btn class="satrtsale uc" dark rounded depressed width="91" height="28"
								@click="putOnSaleBtn">{{ $t("popupMarketStartSale") }}
							</v-btn>
						</template>
					</div>
				</template>
				<!-- 售完状态 -->
				<template v-else-if="value.sellout == true">
					<div class="sold_out">{{ $t("workCardSoldOut") }}</div>
				</template>

			</template>

			<!-- 拍卖 -->
			<template v-if="value.makerType == '2'&&value.sellout == false">

				<!-- 被禁止/被投诉 -->
				<template v-if="value.assetStatus >1||value.contractStatus > 1 ">
					<div v-if="value.assetStatus==4" class="d-flex justify-space-between">
						<div class="mt-n2 text-wrap text--info text--secondary-rd">
							{{ $t("workCardSuspected") }}
						</div>
						<v-btn class="haveAppeal mt-n2 mr-n3" dark rounded width="100" height="28">
							{{ $t("headerUserCenterNavAppealed") }}
						</v-btn>
					</div>
					<div v-else class="d-flex justify-space-between">
						<!-- <div class="mt-1 text-wrap text--info text--secondary-rd">
                  {{ $t("workCardSuspected") }}
               </div> -->
						<img class="mt-n2" src="@/assets/icons/ban.png" width="20px" height="20px" alt=""  @mouseenter="moused()" @mouseleave="leave()"/>
						<v-btn v-if="value.assetStatus ==2 &&value.creator == this.$store.state.did" class="sale ml-4" dark rounded width="100"
							height="28" @click="AppealBtn">{{ $t("workCardAppeal") }}
						</v-btn>
						<div class="explain d-flex flex-row align-center" v-if="show">
							<span>Suspected of violations and has been restricted.</span>
						</div>
					</div>
				</template>
						<template v-else-if="value.assetStatus == 0">
									<!-- 活动未开始 -->
				<template v-if="value.auctionStatus == '0'">
					<div class="d-flex flex-column mt-n12">
						<div>
							<div class="text--sub-title text--secondary-rd">
								{{ $t("popupAuctionStartingBid") }}
							</div>
							<div class="d-flex flex-row ">
								<div>
									<v-img :src="getChainByName(value.marketTokenType).img" alt="logo" width="22px"
										height="22px" />
								</div>
								<div class="ml-2 text--number text--primary-rd font-weight-medium"
									style="line-height: 22px;">
									{{ value.lastBidPrice }}
								</div>
							</div>
						</div>
						<div>
							<div class="text--sub-title text--secondary-rd mt-2">
								{{ $t("popupAuctionStartDate") }}
							</div>
							<div class="mt-1">
								<div class="text--number text--primary-rd font-weight-medium">
									{{ hour }}h {{ minutes }}m {{ second }}s
								</div>
							</div>
						</div>
					</div>
					<v-btn class="withdraw" dark rounded depressed width="91" height="28" @click="AbanBtn">
						{{ $t("Withdraw") }}
					</v-btn>

				</template>


				<!-- 拍卖开始 -->
				<template v-if="  (value.auctionStatus == '1') ||  value.auctionStatus == '3' ">
					<div class="d-flex flex-column mt-n12">
						<div>
							<div class="text--sub-title text--secondary-rd">
								{{ $t("workCardCurrentBid") }}
							</div>
							<div class="d-flex flex-row ">
								<div>
									<div>
										<v-img :src="getChainByName(value.marketTokenType).img" alt="logo" width="22px"
											height="22px" />
									</div>
								</div>
								<div class="ml-2 text--number text--primary-rd font-weight-medium"
									style="line-height: 22px">
									{{ value.lastBidPrice }}
								</div>
							</div>
						</div>
						<div class="mt-1">
							<div class="text--sub-title text--secondary-rd mt-1">
								{{ $t("workCardEndingin") }}
							</div>
							<div class="mt-1">
								<div class="text--number text--primary-rd font-weight-medium">
									{{ hour }}h {{ minutes }}m {{ second }}s
								</div>
							</div>
						</div>
					</div>
					<v-btn class="withdraw" dark rounded depressed width="91" height="28" @click="AbanBtn"
						v-if="value.lastBidPrice != null && value.isNotBid">{{ $t("Withdraw") }}
					</v-btn>

					<!-- 拍卖完了 -->
					<template v-if="value.auctionStatus == '6'">
						<div class="mt-n11">
							<div class="text--sub-title text--secondary-rd">
								{{ $t("Final_price") }}
							</div>
							<div class="d-flex flex-row mt-2">
								<div>
									<v-img :src="getChainByName(value.marketTokenType).img" alt="logo" width="22px"
										height="22px" />
								</div>
								<div class="ml-2 text--number text--primary-rd font-weight-medium"
									style="line-height: 22px">
									{{ value.lastBidPrice }}
								</div>
							</div>
						</div>
						<div class="auction_out">{{ $t("workCardSoldOut") }}</div>
					</template>
				</template>

							</template>
		
			</template>



			<!-- 进行中 -->
			<template v-else-if="value.denomStatus == '4'">
				<div class="d-flex justify-center align-center pt-3 text--info text--secondary-rd">
					{{ $t("workCardInprogress") }}
				</div>
			</template>
		</v-card-text>

		<!-- 弹层部分 -->
		<!-- 申诉 -->
		<v-dialog v-model="Appeal">
			<nftAppeal :constructID="constructID" :parentValue="value" appealType='commonAppeal' @getOpen="Open">
			</nftAppeal>
		</v-dialog>
		<!-- 转送/新建转送 -->
		<v-dialog v-model="Transfer">
			<new-transfer :imgUrl="imgUrl" :address="address" :level="1" :name="name" :contracId="value.id"
				:offSaleTokenIds="offSaleTokenIds" :parentValue="value" @getOpen="opentransfer"
				@promptBoxCommit="promptBoxCommit"></new-transfer>
		</v-dialog>
		<!-- 新增 上架选择 -->
		<v-dialog v-model="openputonsale">
			<!-- <market
            v-if="openputonsale"
            :address="address"
            :onSaleTokenIds="onSaleTokenIds"
            :offSaleTokenIds="offSaleTokenIds"
            :name="name"
            :parentValue="value"
            v-bind="$attrs"
            v-on="$listeners"
            @getOpen="openPutOnSale"
         ></market> -->

			<put-on-sale @getOpen="openPutOnSale" :address="address" :onSaleTokenIds="onSaleTokenIds"
				:offSaleTokenIds="offSaleTokenIds" :supplyLimit="supplyLimit" :name="name" :parentValue="value"
				v-bind="$attrs" v-on="$listeners"></put-on-sale>
		</v-dialog>
		<!-- 销毁 -->
		<v-dialog v-model="Burning">
			<burn v-if="Burning" :pageValue="value" @getOpen="openBurn"></burn>
		</v-dialog>
		<!-- 结束售卖 -->
		<v-dialog v-model="Sold">
			<soldout :address="address" :onSaleTokenIds="onSaleTokenIds" :offSaleTokenIds="offSaleTokenIds"
				:name="value.name" :parentValue="value" @getOpen="openSold"></soldout>
		</v-dialog>
		<!-- 销售统计 -->
		<v-dialog v-model="Total">
			<saletotal v-if="Total" :pageData="value" :address="address" :id="value.id" :name="value.name"
				:minPrice="value.minPrice" @getOpen="openTotal"></saletotal>
		</v-dialog>
		<!-- 卖完了 -->
		<v-dialog v-model="Saleout">
			<soldout @getOpen="openSaleout"></soldout>
		</v-dialog>
		<!-- 未达到目的  放弃   / 及 撤销 使用  -->
		<v-dialog v-model="openaban">
			<auction-aban :parentValue="value" @getOpen="openAban"></auction-aban>
		</v-dialog>
		<!-- 未达到目的待确认 -->
		<v-dialog v-model="openisdeal">
			<auction-is-sure :auctionNo="auctionNo" @getOpen="openisDeal"></auction-is-sure>
		</v-dialog>
		<!-- 达到目的待确认 -->
		<v-dialog v-model="opendeal">
			<auction-in-sure :auctionNo="auctionNo" @getOpen="openDeal"></auction-in-sure>
		</v-dialog>
	 <!-- 被投诉提醒框 -->
    <v-dialog v-model="Banprompt">
      <banprompt v-if="Banprompt"  @getOpenBan="openBanprompt"></banprompt>
    </v-dialog>
	</div>
</template>

<script lang='js'>
	import NftAppeal from "@/components/popup/nftAppeal.vue";
	// import Transfer from "@/components/popup/transfer.vue";
	import newTransfer from "@/components/popup/newTransfer.vue";
	import Burn from "@/components/popup/burn.vue";
	import Soldout from "@/components/popup/soldout.vue";
	import Saletotal from "@/components/popup/saletotal.vue";
	import PutOnSale from "@/components/popup/putOnSale.vue";
	// import countLogo from "@/assets/icons/chain_iris_w.png";
	import AuctionInSure from "@/components/popup/auctionInSure.vue";
	import AuctionAban from "@/components/popup/auctionAban.vue";
	import AuctionIsSure from "@/components/popup/auctionIsSure.vue";
	import Market from "@/components/popup/market.vue";
	import Banprompt from "@/components/popup/banprompt.vue";



	export default {
		components: {
			NftAppeal,
			Burn,
			Soldout,
			Saletotal,
			newTransfer,
			PutOnSale,
			AuctionInSure,
			AuctionAban,
			AuctionIsSure,
			Market,
			Banprompt
		},
		name: "MyCardContent",
		props: {
			value: {
				type: Object
			}
		},
		data: function() {
			return {
				Banprompt:false,
				show: false,
				Appeal: false,
				Transfer: false,
				openputonsale: false,
				Burning: false,
				Sold: false,
				Total: false,
				Saleout: false,
				openaban: false,
				openisdeal: false,
				opendeal: "",
				countLogo: '',
				now: "",
				start: "",
				end: "",
				insure: "",
				day: 0,
				hour: 0,
				minutes: 0,
				second: 0,
				seconds: "",
				hh: 0,
				mm: 0,
				ss: 0,
				moreseconds: "",
				address: "",
				auctionNo: "",
				imgUrl: "",
				name: "",
				level: 1,
				balance: "",
				onSaleTokenIds: "",
				offSaleTokenIds: "",
				supplyLimit: "",
				avliableLenth: 0,
				minPrice: "",
				constructID: "",
				auctionStatus: '',
				nftType: ""
			};
		},
		mounted() {
			this.init();
		},
		methods: {
			init() {
				this.Time(); //调用定时器
				this.address = this.value.contractAddress;
				this.auctionNo = this.value.auctionNo;
				this.imgUrl = this.value.imgUrl;
				this.name = this.value.name;
				this.constructID = this.value.id;
				this.onSaleTokenIds = this.value.onSaleTokenIds;
				this.offSaleTokenIds = this.value.offSaleTokenIds;
				this.supplyLimit = this.value.supplyLimit;
				this.auctionStatus = this.value.auctionStatus;
				this.nftType = this.value.nftType;
				if (this.value.offSaleTokenIds != null) {
					this.avliableLenth = this.value.offSaleTokenIds.length;
				}
				if (this.value.onSaleTokenIds != null) {
					this.avliableLenth += this.value.onSaleTokenIds.length;
				}
			},
			getTime() {
				this.now = Date.parse(new Date());
				this.start = this.value.auctionStartTime;
				this.end = this.value.auctionEndTime;
				// this.start = Date.parse(new Date("2022-01-10 14:18:00"));
				// this.end = Date.parse(new Date("2022-01-10 14:19:00"));
				this.insure = 1000 * 60 * 60 * 48 + this.end; //48小时 暂时1小时

				if (this.value.auctionStatus == 4 || this.value.auctionStatus == 0 || this.value.auctionStatus == 1) {
					if (this.value.auctionNoBid && this.value.auctionNoBid == true) {
						this.insure = 1000 * 60 * 60 * 1 + this.end; //1小时 流拍1小时
					}


					if (this.now < this.start) {
						this.seconds = (this.start - this.now) / 1000;
					} else if (this.now < this.end) {
						this.seconds = (this.end - this.now) / 1000;
					} else if (this.now < this.insure) {
						this.moreseconds = (this.insure - this.now) / 1000;
					}
				}
				if (this.value.auctionStatus == 5) {
					if (this.insure > this.now) {
						this.moreseconds = (this.insure - this.now) / 1000;
					} else {
						this.moreseconds = 0;
					}

				}
			},
			// 天 时 分 秒 格式化函数
			countDown() {
				if (this.seconds > 0) {
					let d = parseInt(this.seconds / (24 * 60 * 60));
					let h = parseInt(((this.seconds / (60 * 60)) % 24) + d * 24);
					this.hour = h < 10 ? "0" + h : h;
					let m = parseInt((this.seconds / 60) % 60);
					this.minutes = m < 10 ? "0" + m : m;
					let s = parseInt(this.seconds % 60);
					this.second = s < 10 ? "0" + s : s;
				}
			},
			moreCountDown() {
				if (this.moreseconds > 0) {
					let d = parseInt(this.moreseconds / (24 * 60 * 60));
					let h = parseInt(((this.moreseconds / (60 * 60)) % 24) + d * 24);
					this.hh = h < 10 ? "0" + h : h;
					let m = parseInt((this.moreseconds / 60) % 60);
					this.mm = m < 10 ? "0" + m : m;
					let s = parseInt(this.moreseconds % 60);
					this.ss = s < 10 ? "0" + s : s;
				}
			},
			//定时器没过1秒参数减1
			Time() {
				setInterval(() => {
					if (this.seconds > 0) {
						this.seconds -= 1;
						this.countDown();
					} else if (this.moreseconds > 0) {
						this.moreseconds -= 1;
						this.moreCountDown();
					} else {
						this.getTime();
					}

				}, 1000);
			},
			moused() {
				this.show = true
			},
			leave() {
				this.show = false
			},
			AbanBtn() {
				this.openaban = true;
			},
			openAban(e) {
				this.openaban = e;
			},
			iSdealBtn() {
				this.openisdeal = true;
			},
			openisDeal(e) {
				this.openisdeal = e;
			},
			dealBtn() {
				this.opendeal = true;
			},
			openDeal(e) {
				this.opendeal = e;
			},
			 openBanprompt(e){
         this.Banprompt = e
      },
			//申诉弹层
			AppealBtn() {
				this.Appeal = true;
			},
			Open(e) {
				this.Appeal = e;
			},
			//转送弹层
			TransferBtn() {
				 this.$gtag.event('转送', { 'event_category': 'Click', 'event_label': '转送' })
				this.Transfer = true;
			},
			opentransfer(e) {
				if (e == 1) {
					this.Transfer = false;
					location.reload();
				} else {
					this.Transfer = e;
				}
			},
			//开始售卖/拍卖弹层
			putOnSaleBtn() {
				 let json = localStorage.getItem("key_user_grade");
      let jsonObj = JSON.parse(json)

         if(jsonObj.grade == 2){
               this.Banprompt = true
               console.log("wxl ---- 2222")
            }else{
              this.openputonsale = true;
            }
				
			},
			openPutOnSale(e) {
				if (e == 1) {
					this.openputonsale = false;
					location.reload();
				} else {
					this.openputonsale = e;
				}
			},
			//销毁弹层
			burnBtn() {
				 this.$gtag.event('销毁', { 'event_category': 'Click', 'event_label': '销毁' })
				this.Burning = true;
			},
			openBurn(e) {
				if (e == 1) {
					this.Burning = false;
					location.reload();
				} else {
					this.Burning = e;
				}
			},
			// 结束售卖弹层
			EndSaleBtn() {
				this.Sold = true;
			},
			openSold(e) {
				if (e == 1) {
					this.Sold = false;
					location.reload();
				} else {
					this.Sold = e;
				}
			},
			//销售统计弹层
			saleTotalBtn() {
				 this.$gtag.event('销售统计', { 'event_category': 'Click', 'event_label': '销售统计' })
				this.Total = true;
			},
			openTotal(e) {
				this.Total = e;
			},
			// 卖完弹层
			saleoutBtn() {
				this.Saleout = true;
			},
			openSaleout(e) {
				this.Saleout = e;
				window.location.reload();
			},
			promptBoxCommit(v) {
				if (v != 3) {
					this.Transfer = false;
				}
			},

		}
	};
</script>

<style lang='scss' scoped>
	.text--primary-rd {
		color: #270645;
	}

	.text--secondary-rd {
		color: #766983;
	}

	.text--info {
		font-size: 10px !important;
		line-height: 1;
		letter-spacing: 0px;
	}

	.text--title {
		display: inline-block;
		font-size: 13px;
		line-height: 24px;
		font-weight: bold;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 230px;
		margin-bottom: -17px;
	}

	.text--sub-title {
		zoom: 0.83;
		font-family: Helvetica;
		font-size: 13px;
		line-height: 1;
	}

	.text--number {
		font-size: 11px;
		line-height: 1;
	}

	.v-divider {
		border-color: #e3e3e3;
	}

	.satrtsale {
		background-color: #1d42ff !important;
		border-radius: 18px;
		font-family: Helvetica;
		font-weight: bold;
		font-size: 12px !important;
		font-weight: bold;
		font-stretch: normal;
		line-height: 100px;
		letter-spacing: 0px;
		color: #ffffff;
		text-align: center;
	}

	.btnbox {
		width: 91px;
		height: 28px;
		box-sizing: border-box;
		padding: 2px;
		// background-image: linear-gradient(
		//         0deg,
		//         #d202fd 0%,
		//         #a538fe 26%,
		//         #776eff 51%,
		//         #01facd 100%
		// );
		background-color: #1d42ff;
		border-radius: 17px;
		margin-left: -5px;
		margin-right: 10px;

		.rd {
			width: 100%;
			height: 100%;
			border: none;
			border-radius: 17px;
			background: #fff;
			font-family: Helvetica;
			font-weight: bold;
			font-size: 12px;
			font-weight: bold;
			font-stretch: normal;
			letter-spacing: 0;
			color: #270645;
		}
	}

	.withdraw {

		width: 91px;
		height: 28px;
		background-color: #1d42ff !important;
		border-radius: 18px;
		font-family: Helvetica;
		font-weight: bold;
		font-size: 12px !important;
		font-weight: bold;
		font-stretch: normal;
		line-height: 100px;
		letter-spacing: 0px;
		color: #ffffff;
		text-align: center;
		border-radius: 17px;
		margin-left: 90px;
		margin-top: -50px;




	}

	.sold_out {
		width: 170px;
		height: 30px;
		margin-top: -10px;
		background-blend-mode: normal, normal;
		border-radius: 18px;
		text-align: center;
		font-family: Helvetica;
		font-weight: bold;
		font-size: 13px;
		font-weight: bold;
		font-stretch: normal;
		line-height: 30px;
		letter-spacing: 0px;
		color: #270645;
	}

	.auction_out {

		height: 36px;
		text-align: center;
		//   background-image: linear-gradient(
		//       91deg,
		//       #d300fd 0%,
		//       #7471ff 58%,
		//       #00fdcf 100%
		//     ),
		//     linear-gradient(#ffffff, #ffffff);
		//   background-blend-mode: normal, normal;
		//   border-radius: 18px;
		//   text-align: center;
		//   font-family: Helvetica;
		//   font-weight: bold;
		//   font-size: 13px;
		//   font-weight: bold;
		//   font-stretch: normal;
		//   line-height: 36px;
		//   letter-spacing: 0px;
		//   color: #ffffff;
		//   position: absolute;
		//   right: -13px;
		//   bottom: 19px;
	}

	.dialodup5 {
		width: 281px;
		height: 281px;
		background-color: rgba(255, 255, 255, 0.9);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;

		.tip {
			font-family: Helvetica;
			font-size: 13px;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0px;
			color: #270645;
			margin-top: 121px;
			margin-bottom: 9px;
			text-align: center;
		}

		.timer {
			font-family: Helvetica;
			font-weight: bold;
			font-size: 15px;
			font-weight: bold;
			font-stretch: normal;
			letter-spacing: 0px;
			color: #270645;
			margin-bottom: 32px;
			text-align: center;
		}

		.drawbtn {
			width: 244px;
			height: 36px;
			background-color: #270645;
			border-radius: 17px;
			margin-left: 18px;
			font-family: Helvetica;
			font-weight: bold;
			font-size: 13px;
			font-weight: bold;
			font-stretch: normal;
			line-height: 36px;
			text-align: center;
			letter-spacing: 0px;
			color: #ffffff;
			margin-top: 210px;
		}

		.dealbtn {
			width: 244px;
			height: 36px;
			background-color: #270645;
			border-radius: 17px;
			margin-left: 18px;
			font-family: Helvetica;
			font-weight: bold;
			font-size: 13px;
			font-weight: bold;
			font-stretch: normal;
			line-height: 36px;
			text-align: center;
			letter-spacing: 0px;
			color: #ffffff;
		}
	}

	.dialodup4 {
		width: 281px;
		height: 281px;
		background-color: rgba(255, 255, 255, 0.9);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;

		.tip {
			font-family: Helvetica;
			font-size: 13px;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0px;
			color: #270645;
			margin-top: 105px;
			margin-left: 28px;
			margin-bottom: 9px;
		}

		.timer {
			font-family: Helvetica;
			font-weight: bold;
			font-size: 15px;
			font-weight: bold;
			font-stretch: normal;
			letter-spacing: 0px;
			color: #270645;
			margin-bottom: 32px;
			text-align: center;
		}

		.btn {
			.box {
				width: 115px;
				height: 36px;
				box-sizing: border-box;
				padding: 2px;
				background-image: linear-gradient(0deg,
						#d202fd 0%,
						#a538fe 26%,
						#776eff 51%,
						#01facd 100%);
				border-radius: 18px;

				.abanbtn {
					width: 100%;
					height: 100%;
					border: none;
					border-radius: 20px;
					background: #fff;
					font-family: Helvetica;
					font-weight: bold;
					font-size: 15px;
					font-weight: bold;
					font-stretch: normal;
					letter-spacing: 0;
					color: #270645;
				}
			}

			.dealbtn {
				width: 116px;
				height: 36px;
				background-color: #270645;
				border-radius: 17px;
				margin-left: 18px;
				font-family: Helvetica;
				font-weight: bold;
				font-size: 13px;
				font-weight: bold;
				font-stretch: normal;
				line-height: 36px;
				text-align: center;
				letter-spacing: 0px;
				color: #ffffff;
			}
		}
	}

	.sale {
		background-color: #1d42ff !important;
		width: 100%;
		height: 100%;
		border: none;
		border-radius: 17px;
		background: #fff;
		font-family: Helvetica;
		font-weight: bold;
		font-size: 12px;
		font-weight: bold;
		font-stretch: normal;
		letter-spacing: 0;
		margin-top: -10px;
	}

	// .ban{
	//   &:hover{
	//     .explain{
	//       display: unset !important;
	//     }
	//   }
	// }
	.explain {
		width: 185px;
		height: 56px;
		background-color: #270645;
		border-radius: 3px;
		position: absolute;
		top: 327px;
		right: 30px;
		z-index: 2;

		span {
			font-family: Helvetica;
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			line-height: 15px;
			letter-spacing: 0px;
			color: #ffffff;
			margin-left: 13px;
			margin-right: 13px;
		}
	}
</style>
