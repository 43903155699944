<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        src="@/assets/btn_close.png"
        alt=""
      />
      <div class="top">
         {{$t("hasReservePriceSuccess")}}
      </div>
      <div class="footer">
        <button class="sub" :class="{'sub-dis': isPay}" submit @click="subCommit"> {{
          $t("popupTransferCommit")
        }} </button>
      </div>
    </v-card>
     <PromptBox ref="promptBox"></PromptBox>
     <uComponents  ref="ucom"></uComponents>
  </div>
</template>
<script>
  import api from "@/api";
  // import { orderPay } from "../../keplr/wallet";
  import PromptBox from "@/components/PromptBox.vue";
  export default {
  components: {  PromptBox },
  data: () => ({
    open: false,
    isPay: false,
  }),
  props: {
     auctionNo: {
      type: String,
    },
    
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
     async subCommit() {
       try {
          this.isPay = true
         let res = await api.home.getFee();
        let auctionNo = this.auctionNo;
      let recipient = res.data.irisMerchantAuctionAddress;
        let orderPrice = "0.02";
        
        let memo = {
          type: "AUCTION_FINALIZE", 
          param: {
            auctionNo: auctionNo, 
          },
        };
        
        let payResult = await this.$wallet.orderPay(
          orderPrice,
          recipient,
          JSON.stringify(memo)
        );
        if(payResult.hash)
        {
          this.$toast("success", this.$t("transactionInprogressSuccess")).then(()=>{
             this.isPay = true
          })
          
          // this.$refs.promptBox.show(this.$t('transactionInprogressSuccess'));
            setTimeout(() => {
             this.$emit("getOpen", false)
              this.isPay = false;
            this.$router.push({ name: "PersonalPageCopy" });
            sessionStorage.setItem("PersonalPage_Tabs", 1);
          }, 10000);
        }
         
       } catch (e) {
         this.$refs.promptBox.show(this.$t('transactionInprogressError'));
          this.isPay = false
         
       }
      
    },
    closeDialog() {
      this.$emit("getOpen", this.open);
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  min-height: 259px;
  width: 600px;
  .top {
    display: flex;
    flex-direction: column;
    margin-top: 66px;
    margin-left:50px;
	margin-right: 50px;
    margin-bottom:35px;
    font-weight: bold;;
	font-size: 20px;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0px;
	color: #270645;
  }
  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }
  .footer {
    margin: 0 auto;
    .sub {
      margin: 30px auto;
      width: 450px;
      height: 51px;
      background-image: linear-gradient(
          90deg,
          #d300fd 0%,
          #a439fe 26%,
          #7471ff 52%,
          #00fdcf 100%
        ),
        linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-weight: bold;;
      font-weight: bold;
      font-size: 20px;
      color: #ffffff;
    }
  }
  &.mobile {
    .top {
      width: 90%;
      margin: 50px auto;
    }
    .footer {
      width: 90%;
     
      .sub {
        width: 80%;
         margin: 0 10%;
      }
    }
  }
}

img {
  display: inline-block;
  width: 126px;
  height: 126px;
  background-color: #8a73ff;
  border-radius: 5px;
  margin: 1px 22px 10px 52px;
}
.sub-dis {
    position: relative;
    pointer-events: none;
    background-image: linear-gradient(
      #766983, 
      #766983), 
     linear-gradient(
      #270645, 
      #270645) !important;
     background-blend-mode: normal, 
      normal;
     border-radius: 25px;
     opacity: 0.9;
}
.sub-dis::after {
    content: "";
    background-image: url(../../assets/loading.gif);
    background-size: 100%;
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: 50px;
    margin-top: 5px;
    
    // box-shadow: 0 0 10px #fff;
    // border-radius: 50%;
}
</style>




