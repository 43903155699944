<template>
  <div class="work">
    <v-img
      class="main-img"
      :src="src"
      :contain="contain"
      width="100%"
      max-height="100%"
      :aspect-ratio="aspectRatio === -1 ? undefined : aspectRatio"
      @click="imgClick"
      style="cursor: pointer"
    >
      <div class="content">
        <v-icon v-if="attribute === 7" class="icon" size="30" color="#fff"
          >mdi-video</v-icon
        >
      </div>
      <div class="amount" v-if="cardType == 'my' || cardType == 'clearmode'">
        <div class="number">{{value.amount}}</div>
      </div>
    </v-img>
  </div>
</template>

<script>
import { getFileSrc } from "@/utils/file";

const WORK_KEY = "WORK";

export default {
  name: "Work",
  inheritAttrs: false,
  props: {
    // -1 处理不设置的情况
    aspectRatio: {
      type: Number,
      default: 1,
    },
    hash: {
      type: String,
    },
    attribute: Number, // 7 video
    contain: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => {}
    },
    cardType:String
  },
  data: () => ({
    src: undefined,
    videoUrl: undefined,
    videoOptions: {},
    
  }),
  watch: {
    hash: {
      handler: async function (hash) {
        
        if (!hash) {
         hash = "QmPcXBirGiUSyjb8i2FQUpNDTtPHBsgo7tviwwm4YQeJ6p"
        }
         this.src = await getFileSrc(WORK_KEY, hash);
      },
      immediate: true,
    },
  },
  methods: {
    imgClick() {
      this.$emit("imgClick");
    },
  },
};
</script>

<style lang="scss" scoped>
.work {
  .content {
    width: 217px;
    height: 217px;
    position: relative;
    height: 100%;
    .icon {
      position: absolute;
      left: 15px;
      bottom: 15px;
    }
  }
  .amount{
      display: flex;
    align-items: center;
     position: relative;
     top: -25px;
    right: -168px;
  	width: 43px;
	height: 23px;
	background-color: rgb(39,6,69,0.3);
	border-radius: 6px;

 
  .number{
      width: 45px;
      font-family: Helvetica;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color:#ffffff;
      text-align: center;
  }
  }
}
</style>
